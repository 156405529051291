import { cn } from "src/lib/utils";

export interface ITestimonyUser {
  avatar: string;
  name: string;
  age: number;
  income: string;
  selected: boolean;
}

const TestimonyUser = (props: ITestimonyUser) => {
  return (
    <div
      className={cn(
        "transition-opacity",
        props.selected ? "opacity-100" : "opacity-40"
      )}
    >
      <div
        className="rounded-rounded w-12 h-12 overflow-hidden"
        style={{
          boxShadow: "0px 0px 0px 2px #41BD86",
          border: "2px solid #121414",
        }}
      >
        <img src={props.avatar} alt={props.name} width={400} height={400} />
      </div>
      <div className="text-right font-normal font-Sora text-grey-w0 text-lg">
        <b>{props.name}</b> - {props.age} عام
      </div>
      <h4 className="text-right text-grey-w1 text-h4 max-lg:text-center max-lg:text-lg linear-text">
        {props.income} د.ت / شهر
      </h4>
    </div>
  );
};

export default TestimonyUser;
