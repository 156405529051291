import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { testimonySectionCopy } from "src/content/testimony";
import { useAnimationCheckpoint } from "src/hooks/use-animation-checkpoint";
import TestimonyDetails from "./testimony-details";
import TestimonyUser from "./testimony-user";

const Testimonies = () => {
  const sectionTarget = useRef(null);
  const [selected, setSelected] = useState(0);

  const usersArray = testimonySectionCopy.videoTestimony.testimony.users;
  const detailsArray = testimonySectionCopy.videoTestimony.testimony.details;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const navNext = () => {
    if (selected < usersArray.length - 1) setSelected(selected + 1);
  };
  const navBack = () => {
    if (selected > 0) setSelected(selected - 1);
  };
  const { checkpointValue } = useAnimationCheckpoint(
    sectionTarget,
    usersArray,
    ["start start", "end 20vh"]
  );

  if (!isDesktopOrLaptop)
    return (
      <div className="flex flex-col gap-8" ref={sectionTarget}>
        <div
          className="relative flex max-lg:flex-col-reverse gap-8 align-middle"
          dir="rtl"
        >
          <div className="max-lg:h-[714px]">
            <div className="max-lg:absolute max-lg:h-[714px] overflow-hidden">
              {detailsArray.map((element) => {
                return (
                  <>
                    <TestimonyDetails
                      rating={element.rating}
                      quote={element.quote}
                      thumbnail={element.thumbnail}
                      vidTitle={element.vidTitle}
                    />
                  </>
                );
              })}
            </div>
          </div>
          <div className="relative">
            <div className="top-[160px] sticky flex lg:flex-col max-lg:justify-center gap-8 lg:w-[284px]">
              {usersArray.map((element, index) => {
                return (
                  <TestimonyUser
                    avatar={element.avatar}
                    name={element.name}
                    age={element.age}
                    income={element.income}
                    selected={checkpointValue === index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="flex flex-col gap-8">
        <div
          className="left-0 z-20 absolute w-1/2 h-[130px]"
          onClick={navNext}
        />
        <div
          className="right-0 z-20 absolute w-1/2 h-[130px]"
          onClick={navBack}
        />

        <div
          className="relative flex max-lg:flex-col-reverse gap-8 overflow-hidden align-middle"
          dir="rtl"
        >
          <div>
            <div className="relative text-grey-w0 overflow-hidden">
              <div
                className="flex w-full transition-transform"
                style={{ transform: `translatex(${0 + selected * 100}%)` }}
              >
                {detailsArray.map((element) => {
                  return (
                    <>
                      <TestimonyDetails
                        rating={element.rating}
                        quote={element.quote}
                        thumbnail={element.thumbnail}
                        isResponsive
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="relative pt-4 min-w-[665px] min-h-[110px]">
            <div
              className="absolute flex justify-left gap-8 px-2 text-grey-w0 transition-transform -translate-y-1/2"
              style={{
                transform: `translatex(${
                  window.innerWidth < 812 || usersArray.length > 4
                    ? 130 * selected + (32 * selected + 2)
                    : 0
                }px)`,
              }}
            >
              {usersArray.map((element, index) => {
                return (
                  <TestimonyUser
                    avatar={element.avatar}
                    name={element.name}
                    age={element.age}
                    income={element.income}
                    selected={selected === index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Testimonies;
