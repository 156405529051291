import LinearContainer from "@components/ui/linear-container/linear-container";
import VideoPlayer from "@components/ui/VideoPlayer";
import { v4 as uuidv4 } from "uuid";
import RatingStar from "../../../../svg/RatingStar";

export interface ITestimonyDetails {
  rating: number;
  thumbnail?: string;
  vidTitle?: string;
  quote?: string;
  isResponsive?: boolean;
}
const TestimonyDetails = (props: ITestimonyDetails) => {
  if (props.isResponsive)
    return (
      <div className="w-full min-w-[100%]">
        <div className="flex flex-col items-end gap-6 w-full">
          <div className="flex gap-2">
            {[...Array(props.rating)].map(() => {
              return <RatingStar key={uuidv4()} />;
            })}
          </div>
          {props.thumbnail ? (
            <>
              <span className="text-grey-w0 text-h3 text-left">
                {props.vidTitle}
              </span>
              <LinearContainer className="!border-t-[1px] !border-r-[1px] !border-l-[1px] overflow-hidden">
                <VideoPlayer
                  videoCode={"6685c51d70a16ee4eb210343"}
                  thumbnail={props.thumbnail}
                  notDark
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(22, 24, 24, 0) 43.82%, rgba(22, 24, 24, .9) 95.63%), linear-gradient(85deg, rgba(64, 202, 189, 0.25) -11.47%, rgba(86, 209, 163, 0.25) 85.1%)",
                  }}
                />
              </LinearContainer>
            </>
          ) : (
            <p className="font-sora text-grey-w0 text-h3 text-left max-lg:text-h5">
              {props.quote}
            </p>
          )}
        </div>
      </div>
    );
  else
    return (
      <div className="lg:w-full last-of-type:h-[50vh] aspect-[1/1.2]">
        <div className="flex flex-col items-end gap-6 lg:py-4 lg:pl-16">
          <div className="flex gap-2">
            {[...Array(props.rating)].map(() => {
              return <RatingStar key={uuidv4()} />;
            })}
          </div>
          {props.thumbnail ? (
            <>
              <span className="text-grey-w0 text-h3 text-left">
                {props.vidTitle}
              </span>
              <LinearContainer className="!border-t-[1px] !border-r-[1px] !border-l-[1px] overflow-hidden">
                <VideoPlayer
                  videoCode={"6685c51d70a16ee4eb210343"}
                  thumbnail={props.thumbnail}
                  notDark
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(22, 24, 24, 0) 43.82%, rgba(22, 24, 24, .9) 95.63%), linear-gradient(85deg, rgba(64, 202, 189, 0.25) -11.47%, rgba(86, 209, 163, 0.25) 85.1%)",
                  }}
                />
              </LinearContainer>
            </>
          ) : (
            <p dir="ltr" className="font-sora text-grey-w0 text-h3">
              {props.quote}
            </p>
          )}
        </div>
      </div>
    );
};

export default TestimonyDetails;
