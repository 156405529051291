

const RatingStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_753_1413)">
        <path
          d="M4.89598 23.328C4.79998 23.328 4.70398 23.28 4.60798 23.232C4.46398 23.136 4.36798 22.896 4.41598 22.704L6.52798 14.832L0.191979 9.69598C-2.13478e-05 9.59998 -0.0480214 9.35998 -2.13552e-05 9.16798C0.0479786 8.97598 0.239979 8.83198 0.431979 8.83198L8.59198 8.39998L11.52 0.76798C11.616 0.62398 11.808 0.47998 12 0.47998C12.192 0.47998 12.384 0.62398 12.432 0.76798L15.36 8.39998L23.52 8.83198C23.712 8.83198 23.904 8.97598 23.952 9.16798C24 9.35998 23.952 9.55198 23.808 9.69598L17.472 14.832L19.584 22.704C19.632 22.896 19.584 23.088 19.392 23.232C19.248 23.328 19.008 23.376 18.864 23.232L12 18.816L5.13598 23.232C5.03998 23.328 4.99198 23.328 4.89598 23.328Z"
          fill="url(#paint0_linear_753_1413)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_753_1413"
          x1="-5.29454"
          y1="0.479973"
          x2="19.8108"
          y2="-2.01049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40CABD" />
          <stop offset={1} stopColor="#56D1A3" />
        </linearGradient>
        <clipPath id="clip0_753_1413">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RatingStar;
